@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Name: custom color palette
       Author: Ilias Ism
       URL: https://gradient.page */

    /* CSS: .bg-gradient { background: var(--gradient) } */
    --gradient: #00416a;
    /* --background: 0 100% 98.42%; */
    --background: 210 15% 96%;
    --foreground: 216 10% 0.84%;

    --content-background: 0, 0%, 98%;

    --muted: 216 10% 92.1%;
    --muted-foreground: 216 5% 42.1%;

    --popover: 216 10% 92.1%;
    --popover-foreground: 216 10% 1.05%;

    --card: 216 10% 92.1%;
    --card-foreground: 216 10% 1.05%;

    --border: 216 15% 89.84%;
    --input: 216 15% 89.84%;

    --primary: 230 3% 12%;
    --primary-foreground: 216 2% 92.1%;

    --secondary: 216 5% 96.05%;
    --secondary-foreground: 216 7% 12.1%;

    --accent: 0 0% 99%;
    --accent-foreground: 216 7% 12.1%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 216 100% 21%;

    --radius: 0.5rem;

    --chart-1: 220 70% 50%;
    --chart-2: 173 58% 39%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 347 77% 50%;
    --sidebar-background: 210 15% 96%;
    --sidebar-foreground: 216 10% 0.84%;
    --sidebar-primary: 230 3% 12%;
    --sidebar-primary-foreground: 216 2% 92.1%;
    --sidebar-accent: 0 0% 99%;
    --sidebar-accent-foreground: 216 7% 12.1%;
    --sidebar-border: 210 15% 83%;
    --sidebar-ring: 216 100% 21%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --content-background: 240, 4%, 8%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-5: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-2: 340 75% 55%;
    --sidebar-background: 216 10% 0.84%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 243 5% 46%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.gradient-icon path {
  fill: url(#gradientFill);
}

.gradient-icon svg {
  display: inline-block;
  width: 1em;
  height: 1em;
}

/* Add the SVG gradient definition to the document */
.svg-gradient-definitions {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  overflow: hidden;
}

@keyframes linear-progress {
  0% {
    transform: translateX(-60%);
  }
  50% {
    transform: translateX(240%);
  }
  100% {
    transform: translateX(-60%);
  }
}
